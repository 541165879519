import Box from '../../../common/src/components/Box';
import { ChapterDescription, ContentWrapper, ImageWrapper, MainHeading } from './ListOfChaptersBlock.style';
import { Link } from 'gatsby';
import Image from '../../../common/src/components/Image';
import Container from '../../../common/src/components/UI/Container';
import React from 'react';
import PropTypes from 'prop-types';

const ChaptersBlockWrapper = ({ row, col, chapterLink, chapterHeadingText, chapterDescriptionText, chapterImage }) => {
  return(
    <Box className="row" {...row}>
      <Box className="col" {...col}>
        <ContentWrapper>
          <Link to={chapterLink}><MainHeading>{chapterHeadingText}</MainHeading></Link>
          <ChapterDescription>{chapterDescriptionText}
          </ChapterDescription>
          <Link to={chapterLink} className="learn__more-btn">
            <span className="hyphen"/>
            <span className="btn_text">čítať viac ...</span>
          </Link>
        </ContentWrapper>
      </Box>
      <Box className="col" {...col}>
        <ImageWrapper>
          <Image src={chapterImage} alt={chapterHeadingText}/>
        </ImageWrapper>
      </Box>
    </Box>
  )
}

// ListOfChaptersBlock style props
ChaptersBlockWrapper.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object
};

// ListOfChaptersBlock default style
ChaptersBlockWrapper.defaultProps = {
  // ListOfChaptersBlock row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px'
  },
  // ListOfChaptersBlock col default style
  col: {
    width: ['100%', '50%', '50%'],
    pl: '15px',
    pr: '15px',
    mb: '30px'
  }
};

export default ChaptersBlockWrapper;