import React from 'react';
import PropTypes from 'prop-types';
import Box from '../../../common/src/components/Box';
import Container from '../../../common/src/components/UI/Container';
import SectionWrapper, {
  ContentArea,
  Heading,
  TextIntro,
  TextOutro
} from './DarkHeaderSection.style';

const ToDistanceOnselfSection = ({ row, col, colFull, title }) => {
  return (
    <SectionWrapper id="uvod">
      <Container width="1260px">
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <ContentArea>
              <TextIntro>
                Séria článkov od The Epoch Times
              </TextIntro>
              <Heading>
                Ako duch <span>komunizmu</span> vládne nášmu svetu
              </Heading>
              <TextOutro>
                od redakčného tímu Deviatich komentárov ku komunistickej strane.
                Duch komunizmu nezmizol s rozpadom komunistickej strany vo východnej Európe,
                naopak, tento zlý duch už vládne nášmu svetu a ľudstvo by si o ňom
                nemalo prechovávať žiadne falošné nádeje.
              </TextOutro>

            </ContentArea>
          </Box>
        </Box>
      </Container>
    </SectionWrapper>
  );
};

// DarkHeaderSection style props
ToDistanceOnselfSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  textStyle: PropTypes.object
};

// DarkHeaderSection default style
ToDistanceOnselfSection.defaultProps = {
  // DarkHeaderSection row default style
  row: {
    flexBox: true,
    alignItems: 'center',
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px'
  },
  // DarkHeaderSection col default style
  col: {
    width: ['100%', '100%', '100%'],
    pl: '15px',
    pr: '15px',
    mb: '30px'
  },
  // DarkHeaderSection col default style
  colFull: {
    width: ['100%', '100%', '100%'],
    pl: '15px',
    pr: '15px',
    mb: '30px'
  }
};

export default ToDistanceOnselfSection;
