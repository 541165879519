import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { charityTheme } from './../common/src/theme/charity';
import { ResetCSS } from './../common/src/assets/css/style';
import { DrawerProvider } from './../common/src/contexts/DrawerContext';
import Navbar from '../containers/Charity/Navbar';
import ListOfChaptersBlock from '../containers/Charity/ListOfChaptersBlock';
import DarkHeaderSection from '../containers/Charity/DarkHeaderSection';
import Footer from '../containers/Charity/Footer';
import "@fontsource/lora/400.css"
import "@fontsource/lora/500.css"
import "@fontsource/lora/600.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/oswald/500.css"

import {
  GlobalStyle,
  CharityWrapper,
  ContentWrapper
} from '../containers/Charity/charity.style';
import SEO from '../components/seo';
import imageGeneral from '../common/src/assets/image/charity/specter/bezKomunizmu_web_1200x746.jpg';


export default () => {
  return (
    <ThemeProvider theme={charityTheme}>
      <Fragment>
        <SEO title="Ako duch komunizmu vládne nášmu svetu."
             description="Ako duch komunizmu vládne nášmu svetu."
             image={imageGeneral}/>
        <ResetCSS/>
        <GlobalStyle/>
        {/* Start charity wrapper section */}
        <CharityWrapper>
          <ContentWrapper>
            <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
              <DrawerProvider>
                <Navbar/>
              </DrawerProvider>
            </Sticky>
            <DarkHeaderSection/>
            <ListOfChaptersBlock/>
          </ContentWrapper>
          <Footer/>
        </CharityWrapper>
        {/* End of charity wrapper section */}
      </Fragment>
    </ThemeProvider>
  );
};
