import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ChaptersBlockWrapper from './chapterWrapper';
import Container from '../../../common/src/components/UI/Container';
import BlockWrapper from './ListOfChaptersBlock.style';
import imageIntroduction from '../../../common/src/assets/image/charity/specter/kapitola_predhovor_small.jpg';
import imagePreface from '../../../common/src/assets/image/charity/specter/kapitola_uvod_small.jpg';
import imageChapter01 from '../../../common/src/assets/image/charity/specter/kapitola_01_small.jpg';
import imageChapter02 from '../../../common/src/assets/image/charity/specter/kapitola_02_small.jpg';
import imageChapter03 from '../../../common/src/assets/image/charity/specter/kapitola_03_small.jpg';
import imageChapter04 from '../../../common/src/assets/image/charity/specter/kapitola_04_small.jpg';
import imageChapter05 from '../../../common/src/assets/image/charity/specter/kapitola_05_small.jpg';
import imageChapter06 from '../../../common/src/assets/image/charity/specter/kapitola_06_small.jpg';
import imageChapter07 from '../../../common/src/assets/image/charity/specter/kapitola_07_small.jpg';
import imageChapter08 from '../../../common/src/assets/image/charity/specter/kapitola_08_small.jpg';
import imageChapter09 from '../../../common/src/assets/image/charity/specter/kapitola_09_small.jpg';
import imageChapter10 from '../../../common/src/assets/image/charity/specter/kapitola_10_small.jpg';
import imageChapter11 from '../../../common/src/assets/image/charity/specter/kapitola_11_small.jpg';
import imageChapter12 from '../../../common/src/assets/image/charity/specter/kapitola_12_small.jpg';
import imageChapter13 from '../../../common/src/assets/image/charity/specter/kapitola_13_small.jpg';
import imageChapter14 from '../../../common/src/assets/image/charity/specter/kapitola_14_small.jpg';
import imageChapter15 from '../../../common/src/assets/image/charity/specter/kapitola_15_small.jpg';
import imageChapter16 from '../../../common/src/assets/image/charity/specter/kapitola_16_small.jpg';
import imageChapter17 from '../../../common/src/assets/image/charity/specter/kapitola_17_small.jpg';
import imageChapter18 from '../../../common/src/assets/image/charity/specter/kapitola_18_small.jpg';
import imageConclusion from '../../../common/src/assets/image/charity/specter/kapitola_zaver_small.jpg';


const ListOfChaptersBlock = ({ row, col }) => {
  return (
    <BlockWrapper id="history">
      <Container width="1260px">
        <ChaptersBlockWrapper
          row={row}
          col={col}
          chapterLink='/predhovor'
          chapterHeadingText='Predhovor'
          chapterDescriptionText='Hoci sa komunistické režimy východnej Európy rozpadli, zlý duch komunizmu nezmizol. Naopak, tento zlý
                duch už vládne nášmu svetu a ľudstvo by si o ňom nemalo prechovávať žiadne falošné nádeje.'
          chapterImage={imageIntroduction}
        />
        <ChaptersBlockWrapper
          row={row}
          col={col}
          chapterLink='/uvod'
          chapterHeadingText='Úvod'
          chapterDescriptionText='Zrútenie komunistických režimov v Sovietskom zväze a vo východnej Európe znamenalo
                koniec polstoročia dlhej studenej vojny medzi kapitalistickým táborom na Západe a komunistickým táborom
                na Východe. Mnohí ľudia boli preto optimistickí a verili, že komunizmus sa stal históriou, ktorá
                pominula.'
          chapterImage={imagePreface}
        />
        <ChaptersBlockWrapper
          row={row}
          col={col}
          chapterLink='/kapitola_01'
          chapterHeadingText='Prvá kapitola: Diablove stratégie na zničenie ľudstva'
          chapterDescriptionText='Duch komunizmu sa už po celé stáročia snaží skaziť a zničiť ľudstvo. Začalo to duchovným pokrivením človeka,
        oddelením ho od jeho božského pôvodu. Duch komunizmu takto viedol ľudí vo svete, aby opustili svoje tisícročné
        kultúrne tradície, ktoré Nebesá starostlivo usporiadali ako pravé štandardy pre ľudskú existenciu.'
          chapterImage={imageChapter01}
        />
        <ChaptersBlockWrapper
          row={row}
          col={col}
          chapterLink='/kapitola_02'
          chapterHeadingText='Druhá kapitola: Európske začiatky komunizmu'
          chapterDescriptionText='Mnohé proroctvá, ktoré boli predpovedané v ortodoxných náboženstvách, sa naplnili. Potvrdili sa aj predpovede Nostradama a proroctvá odovzdávané v kultúrach po celom svete, od Peru až po Kóreu. Prekvapivo presné prorocké texty nájdeme tiež v čínskych dejinách od dynastie Chan až po dynastiu Ming.'
          chapterImage={imageChapter02}
        />
        <ChaptersBlockWrapper
          row={row}
          col={col}
          chapterLink='/kapitola_03'
          chapterHeadingText='Tretia kapitola: Tyrania na Východe'
          chapterDescriptionText='Od doby, čo sa komunistická strana v Sovietskom zväze chopila moci, uplynulo celé storočie. Podľa záznamov
        zhromaždených americkým kongresom sú komunistické režimy zodpovedné za smrť najmenej 100 miliónov ľudí. Čierna kniha komunizmu popisuje túto históriu vraždenia, čerpajúc z
        dokumentov odtajnených vládami krajín bývalého Sovietskeho zväzu a východnej Európy, ako aj zo záznamov o
        obetiach komunistických politických kampaní v Číne, Severnej Kórei a ďalších komunistických krajinách.'
          chapterImage={imageChapter03}
        />
        <ChaptersBlockWrapper
          row={row}
          col={col}
          chapterLink='/kapitola_04'
          chapterHeadingText='Štvrtá kapitola: Vyvážanie revolúcie'
          chapterDescriptionText='Kult komunizmu sa po celom svete šíri pomocou násilia a podvodov. Kým komunistické superveľmoci, ako Sovietsky
        zväz alebo Čína, použili vojenskú silu na vnútenie svojho politického systému slabším krajinám, nemalo by sa
        zabúdať na to, že násilné dobývanie sveta komunistickým režimom bolo podporované – a do značnej miery umožnené –
        efektívnym využívaním propagandy.'
          chapterImage={imageChapter04}
        />
        <ChaptersBlockWrapper
          row={row}
          col={col}
          chapterLink='/kapitola_05'
          chapterHeadingText='Piata kapitola: Infiltrácia Západu'
          chapterDescriptionText='Americké prezidentské voľby roku 2016 patrili medzi najdramatickejšie za posledných niekoľko desaťročí. Volebné
        súboje boli plné prekvapivých zvratov, ktoré pretrvávali aj po voľbách. Víťaz Donald Trump, kandidát za
        Republikánsku stranu, sa ocitol v obkľúčení negatívnej mediálnej publicity a&nbsp;čelil protestom v mestách po
        celej krajine.'
          chapterImage={imageChapter05}
        />
        <ChaptersBlockWrapper
          row={row}
          col={col}
          chapterLink='/kapitola_06'
          chapterHeadingText='Šiesta kapitola: Vzbura proti Bohu'
          chapterDescriptionText='Takmer všetky národy sveta majú svoje vlastné starodávne mýty a legendy,
          ktoré hovoria o stvorení človeka na
        obraz Boží. Tieto tradičné viery ustanovujú pre ľudí základy morálky a kultúry a zanechávajú cestu k návratu do
        neba pre tých, ktorí vo svojich Bohov veria. Na Východe i na Západe existujú legendy o tom, ako Nü-wa a Jehova
        stvorili svojich ľudí.'
          chapterImage={imageChapter06}
        />
        <ChaptersBlockWrapper
          row={row}
          col={col}
          chapterLink='/kapitola_07'
          chapterHeadingText='Siedma kapitola: Zničenie rodiny'
          chapterDescriptionText='Rodina je stavebným kameňom ľudskej spoločnosti a umožňuje ľuďom nielen
          vychovávať deti v stabilnom a
        starostlivom prostredí, ale aj odovzdávať vedomosti z jednej generácie na ďalšiu. Manželstvo je posvätná
        inštitúcia usporiadaná nebesami pre ľudstvo, aby vytváralo rodiny a chránilo tradičné dedičstvo a morálku.'
          chapterImage={imageChapter07}
        />
        <ChaptersBlockWrapper
          row={row}
          col={col}
          chapterLink='/kapitola_08'
          chapterHeadingText='Ôsma kapitola: Ako komunizmus zasieva chaos v politike'
          chapterDescriptionText='Komunistická ideológia nezmizla z dejín s koncom studenej vojny.
          Pred a po páde komunistických režimov vo východnej Európe sa cez rozvracanie rozšírili
          marxistické myšlienky do celého slobodného sveta a mnohých demokraticky
          zvolených vlád sa zmocnili ľavicové hnutia.'
          chapterImage={imageChapter08}
        />
        <ChaptersBlockWrapper
          row={row}
          col={col}
          chapterLink='/kapitola_09'
          chapterHeadingText='Deviata kapitola: Ekonomická pasca komunizmu'
          chapterDescriptionText='Vplyv komunizmu je prítomný v každom sektore nášho
          súčasného ekonomického systému. Trend neustále sa
        rozširujúcej vlády sa stal normou, pričom prakticky každá krajina na Zemi sa odkláňa od klasických princípov
        voľného trhu a tiahne ku komunistickej alebo socialistickej ekonomike.'
          chapterImage={imageChapter09}
        />
        <ChaptersBlockWrapper
          row={row}
          col={col}
          chapterLink='/kapitola_10'
          chapterHeadingText='Desiata kapitola: Podkopávanie právneho systému'
          chapterDescriptionText='Právo je mocnou silou spravodlivosti, ktorá
           potvrdzuje dobro a trestá zlo. Tí, ktorí píšu zákony, musia chápať
           čo je dobré a čo zlé. Z hľadiska viery majú tieto kritériá božský pôvod.
           Zákony, ktoré riadia ľudskú spoločnosť, vychádzajú z posvätných písiem a svätých učení.'
          chapterImage={imageChapter10}
        />
        <ChaptersBlockWrapper
          row={row}
          col={col}
          chapterLink='/kapitola_11'
          chapterHeadingText='Jedenásta kapitola: Znesvätenie umenia'
          chapterDescriptionText='V priebehu mnohých rokov ľudskej civilizácie
          človek uvažoval o tom, v&nbsp;čom tkvie skutočná krása. Veriaci ľudia
          vedia, že všetky divy sveta majú božský pôvod. Umenie s vnútornou
          hĺbkou sa pokúša napodobniť a zobraziť krásu neba v ľudskom svete.'
          chapterImage={imageChapter11}
        />
        <ChaptersBlockWrapper
          row={row}
          col={col}
          chapterLink='/kapitola_12'
          chapterHeadingText='Dvanásta kapitola: Sabotovanie školstva'
          chapterDescriptionText='Vzdelanie hrá v rozvoji človeka a jeho
          sebauplatnení dôležitú úlohu. Udržuje stabilitu v spoločnosti a zaisťuje
           budúcnosť národa. Žiadna veľká civilizácia v dejinách ľudstva
           nebrala vzdelávanie na ľahkú váhu.'
          chapterImage={imageChapter12}
        />
        <ChaptersBlockWrapper
          row={row}
          col={col}
          chapterLink='/kapitola_13'
          chapterHeadingText='Trinásta kapitola: Ovládnutie médií'
          chapterDescriptionText='Vplyv médií v modernej spoločnosti je obrovský
           a&nbsp;rastie každým dňom. Preniká do komunít všetkých veľkostí,
           od lokálnych až po globálne. S tým, ako rastie popularita sociálnych
           médií a&nbsp;šíri sa obsah vytvorený používateľmi,
           internet výrazne umocnil rýchlosť a dosah audiovizuálnej komunikácie.'
          chapterImage={imageChapter13}
        />
        <ChaptersBlockWrapper
          row={row}
          col={col}
          chapterLink='/kapitola_14'
          chapterHeadingText='Štrnásta kapitola: Populárna kultúra – bezuzdný hedonizmus'
          chapterDescriptionText='Boh stvoril ľudstvo a v priebehu dlhého trvania
          dejín pre neho ustanovil ortodoxnú kultúru, podľa ktorej by ľudstvo malo žiť.
          Hoci národy vo svete sa líšia, zdieľajú nápadne podobné základné hodnoty.'
          chapterImage={imageChapter14}
        />
        <ChaptersBlockWrapper
          row={row}
          col={col}
          chapterLink='/kapitola_15'
          chapterHeadingText='Pätnásta kapitola: Komunistické korene terorizmu'
          chapterDescriptionText='Od teroristického útoku z 11. septembra 2001
          je západná verejnosť oboznámená s globálnym teroristickým
          hnutím a jeho predstaviteľmi. Už menej sa však vie o blízkom
          vzťahu medzi terorizmom a komunizmom..'
          chapterImage={imageChapter15}
        />
        <ChaptersBlockWrapper
          row={row}
          col={col}
          chapterLink='/kapitola_16'
          chapterHeadingText='Šestnásta kapitola: Komunizmus v pozadí environmentalizmu'
          chapterDescriptionText='Stvoriteľ poskytuje podmienky pre ľudský život a pre všetky veci na zemi.
          Tradičné kultúry zdôrazňujú priaznivý, symbiotický vzťah medzi
          človekom a prírodou.'
          chapterImage={imageChapter16}
        />
        <ChaptersBlockWrapper
          row={row}
          col={col}
          chapterLink='/kapitola_17'
          chapterHeadingText='Sedemnásta kapitola: Globalizácia a komunizmus'
          chapterDescriptionText='Vďaka moderným komunikačným prostriedkom,
           doprave, počítačom a digitálnym sieťam sa vzdialenosti skrátili a
           hranice, ktoré stáli v ceste po tisíce rokov, sú dnes zmazané.'
          chapterImage={imageChapter17}
        />
        <ChaptersBlockWrapper
          row={row}
          col={col}
          chapterLink='/kapitola_18'
          chapterHeadingText='Osemnásta kapitola: Globálne ambície ČKS'
          chapterDescriptionText='Duch komunizmu sa v našom svete ustanovil
          buď prostredníctvom zjavnej totalitnej vlády, alebo skrytej
          rozvratnej činnosti. Tento proces trval veľkú časť modernej histórie.'
          chapterImage={imageChapter18}
        />
        <ChaptersBlockWrapper
          row={row}
          col={col}
          chapterLink='/zaver'
          chapterHeadingText='Záver: Ako duch komunizmu vládne nášmu svetu'
          chapterDescriptionText='V priebehu dlhej histórie zažilo ľudstvo
          obdobie nádhery a slávy, prežilo však tiež nespočetné množstvo
          tragédií a katastrof. Keď sa obzrieme späť, zistíme,
          že predpokladom poctivej vlády, ekonomického rozmachu,
          prekvitajúcej kultúry a národnej prosperity je morálna bezúhonnosť.
          Naopak, mravná skazenosť je predzvesťou pádu národov a
          zániku celých civilizácií.'
          chapterImage={imageConclusion}
        />
      </Container>
    </BlockWrapper>
  );
};

// ListOfChaptersBlock style props
ListOfChaptersBlock.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object
};

// ListOfChaptersBlock default style
ListOfChaptersBlock.defaultProps = {
  // ListOfChaptersBlock row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px'
  },
  // ListOfChaptersBlock col default style
  col: {
    width: ['100%', '50%', '50%'],
    pl: '15px',
    pr: '15px',
    mb: '30px'
  }
};

export default ListOfChaptersBlock;
